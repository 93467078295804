import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { graphql, navigate } from 'gatsby'
import { Container, Spacer, Column, Button, Grid } from 'ascential-horizon'
import { StickyShareButtons, InlineShareButtons } from 'sharethis-reactjs'
import { windowExists } from '../utils'
import { useWindowWidth } from '../utils/hooks'
import renderContent from '../utils/componentRenderer'
import { generateSlug } from '../utils/slugifier'
import Layout from '../Layout'
import {
  stickyProps,
  mobileProps,
} from '../components/ShareButtons/ShareButtonsProps'
import {
  DateLabel,
  SubtitleWrapper,
  ArticleHeader,
  ArticleTitle,
} from '../components/Digest/Digest.style'
import { useIdentity } from '../providers/identityContext'
import getThemeConfig from '../providers/config/themeConfig'

const TemplateDigestPage = ({ data, pageContext, location }) => {
  const { loggedIn, user } = useIdentity()
  // const { breakpoints } = useTheme()
  // console.log(breakpoints)
  const windowWidth = useWindowWidth()
  // console.log(windowWidth)
  // const isMobile = windowWidth < parseInt(breakpoints.large, 10)
  const isMobile = windowWidth < parseInt('992px', 10)

  const {
    footerNav,
    header,
    headerAuth,
    home: { footer },
    page: { pageType, slug, title, category, publicationDate, content },
  } = data

  const {
    footer: { type },
    header: { variation },
  } = getThemeConfig()

  const getHeaderProps = () => {
    if (user?.loading) {
      return <></>
    }
    const headerProps =
      variation === 'nav'
        ? {
            ...(loggedIn && headerAuth ? headerAuth : header),
          }
        : {
            ...data.home.header,
          }
    return headerProps
  }

  const footerProps = type === 'menu' ? footerNav : footer

  const layoutProps = {
    header: getHeaderProps(),
    footer: footerProps,
    slug,
    pageType,
    pageContext,
    location,
  }

  const onSelectCategory = () => {
    const path = `/digest/${generateSlug(category)}`
    navigate(path)
  }

  const sharePageProps = {
    title: pageContext.pageTitle,
    url: location.href,
    description: pageContext.pageDescription, // (defaults to og:description or twitter:description)
    message: `Check out ${pageContext.pageTitle} ${location.href}`, // (only for email sharing)
    subject: 'Check out this Lion Digest article!', // (only for email sharing)
  }

  return (
    <Layout {...layoutProps}>
      <Container>
        <Grid>
          <Column width={[0, 0, 1, 2]} />
          <Column width={[12, 12, 10, 8]}>
            <ArticleHeader>
              <Button
                variant="secondary"
                startIcon="ChevronLeft"
                onClick={(e) => {
                  e.preventDefault()
                  return windowExists && window.history.back()
                }}>
                Back
              </Button>
              <ArticleTitle>{title}</ArticleTitle>
              <SubtitleWrapper>
                <Button variant="secondary" onClick={onSelectCategory}>
                  {category}
                </Button>
                <DateLabel>
                  {moment(publicationDate).format('D MMMM YYYY')}
                </DateLabel>
              </SubtitleWrapper>
            </ArticleHeader>
          </Column>
        </Grid>
        <Spacer>{renderContent(content)}</Spacer>
      </Container>

      {isMobile ? (
        <Spacer space="medium">
          <InlineShareButtons
            config={{
              ...mobileProps,
              ...sharePageProps,
            }}
          />
        </Spacer>
      ) : (
        <StickyShareButtons
          config={{
            ...stickyProps,
            ...sharePageProps,
          }}
        />
      )}
    </Layout>
  )
}

TemplateDigestPage.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export const templateDigestPageQuery = graphql`
  query($id: String!, $rootId: String!) {
    header: contentfulNav(instanceName: { eq: "Main Menu" }) {
      ...navQuery
    }
    headerAuth: contentfulNav(instanceName: { eq: "Main Menu Auth" }) {
      ...navQuery
    }
    footerNav: contentfulFooter(instanceName: { eq: "Footer" }) {
      ...footerQuery
    }
    home: contentfulTemplateHomePage(id: { eq: $rootId }) {
      ...header
      footer {
        ...footerQuery
      }
    }
    page: contentfulTemplateDigestPage(id: { eq: $id }) {
      pageType {
        slug
      }
      slug
      title
      category
      publicationDate
      content {
        __typename
        ... on Node {
          ...multipleComponents
        }
      }
    }
  }
`

export default TemplateDigestPage

// For more details check: https://github.com/sharethis-github/sharethis-reactjs //

export const defaultProps = {
  alignment: 'left', // alignment of buttons (left, center, right)
  color: 'social', // set the color of buttons (social, white)
  enabled: true, // show/hide buttons (true, false)
  font_size: 16, // font size for the buttons
  labels: null, // button labels (cta, counts, null)
  language: 'en', // which language to use (see LANGUAGES)
  networks: [
    // which networks to include
    'linkedin',
    'twitter',
    'whatsapp',
    'messenger',
    'email',
    'sharethis',
  ],
  padding: 12, // padding within buttons (INTEGER)
  radius: 4, // the corner radius on each button (INTEGER)
  show_total: false, // show/hide the total share count (true, false)
  size: 40, // the size of each button (INTEGER)
}

export const optionalProps = {
  // OPTIONAL PARAMETERS
  // url: 'https://www.money2020.com/',     // (defaults to current url)
  // image: 'https://bit.ly/2CMhCMC',       // (defaults to og:image or twitter:image)
  // description: 'Description',            // (defaults to og:description or twitter:description)
  // title: 'This is the title',            // (defaults to og:title or twitter:title)
  // username: 'username',                  // (only for twitter sharing)
  // message: 'custom email text',          // (only for email sharing)
  // subject: 'custom email subject',       // (only for email sharing)
}

// These are the Props for the Inline variant of ShareButtons
export const inlineProps = {
  ...defaultProps,
  ...optionalProps,
}

// These are the Props for the Sticky variant of ShareButtons
export const stickyProps = {
  ...defaultProps,
  ...optionalProps,
  top: 160, // offset in pixels from the top of the page
  alignment: 'left', // alignment of buttons (left, center, right)
  min_count: 0, // hide react counts less than min_count (INTEGER)
  hide_desktop: false, // hide buttons on desktop (true, false)
  show_mobile: true, // show/hide the buttons on mobile (true, false)
  show_toggle: true, // show/hide the toggle buttons (true, false)
}

// These are the Props for the Sticky variant of ShareButtons
export const mobileProps = {
  ...defaultProps,
  ...optionalProps,
  alignment: 'center', // alignment of buttons (left, center, right)
  spacing: 10, // the spacing between buttons (INTEGER)
  radius: 0, // the corner radius on each button (INTEGER)
}

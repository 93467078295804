// Styled components for lions digest listing and article pages
import styled from '@emotion/styled'
import { Select } from 'ascential-horizon'

export const CategoryHeader = styled('div')(({ theme: { mediaQueries } }) => ({
  margin: '30px auto',

  '& > button': {
    width: 'fit-content',
  },

  [mediaQueries.small]: {
    margin: '30px auto 0px auto',
  },
}))

export const CategoryContainer = styled('div')(
  ({ theme: { mediaQueries } }) => ({
    display: 'block',
    marginBottom: '25px',

    [mediaQueries.small]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
)

export const SelectField = styled(Select)({
  marginRight: '20px',
})

export const ClearFilters = styled('div')(({ theme: { colors } }) => ({
  fontWeight: 'bold',
  borderBottom: `1px solid ${colors.primary}`,
  cursor: 'pointer',
}))

export const ArticleTitle = styled('h2')({
  margin: '3rem 0',
})

export const DateLabel = styled('span')(() => ({
  color: '#7E7F7E',
  fontWeight: 600,
  marginLeft: '20px',
  width: 'fit-content',
}))

export const ArticleHeader = styled('div')(() => ({
  margin: '30px auto 0px auto',
  padding: '12px',

  '& > button': {
    width: 'fit-content',
  },
}))

export const SubtitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',

  '& button': {
    width: 'fit-content',
  },
}))
